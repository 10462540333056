import { mapMutations } from "vuex";

export default {
	methods: {
		...mapMutations({
			setHasSidebar: 'sideBar/setHasSideBar',
			setMinimizedSidebar: 'sideBar/setMinimized',
			setPermanentSidebar: 'sideBar/setPermanent',
			setShowSidebar: 'sideBar/setShow',
		})
	}
}
