import AbstractContextualizedModuleGuard from '@/mixins/ModulesGuards/AbstractContextualizedModuleGuard'
import Service from '@/services/Holding/HoldingService'
import { Events, Bus } from '@/events/Holding/HoldingEvents'

export default {
	mixins: [AbstractContextualizedModuleGuard],
	data: function () {
		return {
			moduleName: 'holding-administration',
			eventBus: Bus,
			service: Service,
			events: Events
		}
	},
	watch: {
		hasModule: {
			immediate: true,
			handler: function () {
				if (!this.hasModule) {
					this.appService.goTo('home')
				}
			}
		}
	}
}
