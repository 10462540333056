<template>
	<w-layout column fill-height>
		<v-list>
			<v-list-tile v-for="(action, index) in holdingModules" :key="index" :value="index == selectedIndex" @click="goTo(action.route)">
				<v-list-tile-avatar>
					<v-icon :color="index == selectedIndex ? 'primary' : 'default'">{{ action.icon }}</v-icon>
				</v-list-tile-avatar>
				<v-list-tile-content>
					<v-list-tile-title v-text="action.name" />
				</v-list-tile-content>
			</v-list-tile>
		</v-list>
	</w-layout>
</template>

<script>
import HoldingModuleGuard from '@/mixins/ModulesGuards/Holding/HoldingModuleGuard'

export default {
    name: 'NavDrawerHolding',
    mixins: [HoldingModuleGuard],
    data: function () {
        return {
            mini: false,
            sidebar: false,
        }
    },
    computed: {
        holdingModules: function () {
            return this.$parent.modules
        },
        selectedIndex: function () {
            const routeName = this.$route.name

            const routeIndex = this.holdingModules.findIndex(holdingModule => {
                return holdingModule.route == routeName || routeName.startsWith(`${holdingModule.route}-`)
            })

            return routeIndex == -1 ? null : routeIndex
        }
	},
	methods: {
		getAppEventsActionsMapping: function () {
			return [
				{ event: this.appEvents.SIDEBAR_MINIMIZED, action: this.onSidebarMinimized },
				{ event: this.appEvents.SIDEBAR_MAXIMIZED, action: this.onSidebarMaximized },
				{ event: this.appEvents.SHOW_SIDEBAR, action: this.onShowSideBar },
				{ event: this.appEvents.HIDE_SIDEBAR, action: this.onHideSideBar }
			]
		},
		goTo: function (routeName) {
			this.appService.goTo(routeName)
		},
		onSidebarMaximized: function () {
			this.mini = false
		},
		onSidebarMinimized: function () {
			this.mini = true
		},
		onShowSideBar: function () {
			this.sidebar = true
		},
		onHideSideBar: function () {
			this.sidebar = false
		}
	}
}
</script>