<template>
	<v-layout column fill-height>
		<v-layout v-if="loading" align-center fill-height justify-center>
			<v-progress-circular color="primary" indeterminate size="50" width="6" />
		</v-layout>
		<NoHoldingModuleAvailable v-else-if="modules.length == 0" />
		<router-view v-else></router-view>
	</v-layout>
</template>

<script>
import Vue from 'vue'
import NavDrawerHolding from '@/components/Holding/NavDrawerHolding'
import HoldingModuleGuard from '@/mixins/ModulesGuards/Holding/HoldingModuleGuard'
import HandleSideBarMixin from "@/mixins/SideBar/HandleSideBarMixin";

export default {
	name: 'HoldingManager',
	components: {
		NoHoldingModuleAvailable: () => ({
			component: import('@/components/Holding/NoHoldingModuleAvailable')
		})
	},
	reactiveProvide: {
		include: ['holding_id'],
		name: 'holdingContext'
	},
	mixins: [
		HoldingModuleGuard,
		HandleSideBarMixin
	],
	data: function () {
		return {
			holding: null,
			loading: false
		}
	},
	computed: {
		modules: function () {
			const navBarModuleKeys = ['dashboard', 'workspaces', 'holding-customers', 'holding-team']
			return this.$store.state.modules.holding
				.filter(({ name }) => {
					return navBarModuleKeys.includes(name)
				})
				.map(({ icon, name, title }) => {
					return {
						icon,
						name: title,
						route: name
					}
				})
		},
		holding_id: function () {
			return this.holding?.id
		}
	},
	watch: {
		vendorId: {
			handler: 'loadHolding'
		}
	},
	mounted: function () {
		if (this.vendorId) {
			this.loadHolding()
		}
	},
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this);
		this.setHasSidebar(false);
		this.setShowSidebar(false);
	},
	methods: {
		loadHolding: function () {
			this.loading = true
			this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
			return this.service
				.findHolding(this.vendorId, { show_error: false })
				.then(holding => {
					this.holding = holding
					return this.loadModules()
				})
				.then(() => {
					if (this.modules.length > 0) {
						this.mountNavDrawer()
					}
				})
				.finally(() => {
					this.loading = false
				})
		},
		loadModules: function () {
			return this.service.getHoldingModules(this.holding_id).then(() => {
				if (this.$route.name == 'holding-administration' && this.modules.length > 0) {
					return this.appService.goTo(this.modules[0].route, true)
				}
			})
		},
		mountNavDrawer: function () {
			this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
			const NavDrawerClass = Vue.extend(NavDrawerHolding)
			const navDrawerComponent = new NavDrawerClass({
				parent: this
			})
			this.appEventBus.emit(this.appEvents.SET_SIDEBAR_CONTENT, {
				canMinimize: this.$vuetify.breakpoint.mdAndUp,
				startHidden: false,
				canClose: false,
				moduleInstance: navDrawerComponent,
				title: this.$t('holding.title'),
				emitter: this
			});
			this.setHasSidebar(true);
			this.setShowSidebar(true);
		}
	}
}
</script>
